/**
 * IMPORTS
 */

import React from 'react';

/**
 * CORE
 */

export interface CaptureContextData {
  captureIndex: number;
  expectedCount: number;
}

const CaptureContext = React.createContext<CaptureContextData>({ captureIndex: 0, expectedCount: 1 });

export default CaptureContext;
