import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore, Store, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { ChakraProvider } from '@chakra-ui/react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { AmplitudeEventTracker, ConsoleEventTracker, initAnalytics } from './analytics';
import { MSState } from './reducers';
import { AnyAction } from './actions';
import rootSaga from './sagas';
import { resources } from './utils/translate';
import vars from './variables';

import reducer, { initial } from './reducers';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

function getStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  const composeWithDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store: Store<MSState, AnyAction> = createStore(
    reducer,
    initial,
    composeWithDevTools(
      applyMiddleware(...middleware),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}

const store = getStore();

i18next
  .use(LanguageDetector)
  .init({
    load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
    interpolation: { escapeValue: false },
    resources,
    fallbackLng: 'en',
    detection: { caches: ['localStorage'] },
  });
  
initAnalytics(
  vars.amplitudeApiKey
    ? new AmplitudeEventTracker()
    : new ConsoleEventTracker(),
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
