/**
 * IMPORTS
 */

import React from 'react';
import { Center, Container, Link, Text } from '@chakra-ui/react';
import { Trans } from 'react-i18next';


import { useTranslation } from '../../utils/translate';

/**
 * CORE
 */

function Footer() {
  const { t } = useTranslation();
  const privacyPolicy = (
    <Link
      href={t('footer.privacy-policy-url') as string}
      target="_blank"
    >
      Privacy Policy
    </Link>
  );

  return (
    <Container>
      <Center padding="0.5rem">
        <Text
          color="gray.400"
          fontSize="0.65rem"
        >
          <Trans i18nKey="footer.content" ns="webapp" components={[privacyPolicy]} />
        </Text>
      </Center>
    </Container>
  )
}

export default Footer;
