/**
 * IMPORTS
 */

import { Analytics } from './Analytics';

/**
 * CORE
 */

let instance: Analytics;

export function initAnalytics(...args: ConstructorParameters<typeof Analytics>): void {
  instance = new Analytics(...args);
}

export function analytics(): Analytics {
  if (!instance) {
    initAnalytics();
  }
  return instance;
}
