/**
 * IMPORTS
 */

import { createSelector } from 'reselect';
import { MSState } from '../reducers';

/**
 * TYPES
 */

export enum Step {
  CAPTURE,
  UPLOAD,
  THEME,
  WAIT,
  SHARE,
}

/**
 * CORE
 */

const getCaptureState = (state: MSState) => state.capture;
const getUploadState  = (state: MSState) => state.upload;
const getThemeState   = (state: MSState) => state.theme;
const getContentState = (state: MSState) => state.content;
const getSessionState = (state: MSState) => state.session;

export const getSessionId = createSelector(
  getSessionState,
  (sessionState) => sessionState.id,
);

export const getPhotoUri = createSelector(
  getCaptureState,
  (captureState) => captureState.photoUri,
);

export const getExpectedCount = createSelector(
  getCaptureState,
  (captureState) => captureState.expected,
);

export const getValidatedCount = createSelector(
  getCaptureState,
  (captureState) => captureState.validated,
);

export const isValidationComplete = createSelector(
  getExpectedCount,
  getValidatedCount,
  (expected, validated) => validated === expected,
);

export const isUploading = createSelector(
  getUploadState,
  (uploadState) => uploadState.uploading > 0,
);

export const getUploadInfos = createSelector(
  getUploadState,
  (uploadState) => uploadState.uploadInfos,
);

export const getUploadedCount = createSelector(
  getUploadState,
  (uploadState) => uploadState.uploaded,
);

export const getCallbackUrl = createSelector(
  getUploadState,
  (uploadState) => uploadState.callbackUrl,
);

export const hasNotified = createSelector(
  getUploadState,
  (uploadState) => uploadState.hasNotified,
);

export const getThemes = createSelector(
  getThemeState,
  (themeState) => themeState.themes,
);

export const getPickedThemes = createSelector(
  getThemeState,
  (themeState) => themeState.picked,
);

export const getPickedContent = createSelector(
  getPickedThemes,
  getContentState,
  (picked, contentState) => (
    contentState.list.find(({ productId, status }) => productId === picked.join('_') && status === 'AVAILABLE')
  ),
);

export const getStatusUrl = createSelector(
  getContentState,
  (contentState) => contentState.statusUrl,
);

export const getCurrentStep = createSelector(
  isValidationComplete,
  isUploading,
  hasNotified,
  getThemes,
  getPickedThemes,
  getPickedContent,
  (validatedAllCaptures, uploading, hasCalledBack, themes, pickedThemes, hasFetchedResultContent) => {
    if (!validatedAllCaptures) {
      return Step.CAPTURE;
    }

    if (uploading || !hasCalledBack) {
      return Step.UPLOAD;
    }

    if (pickedThemes.length < themes.length) {
      return Step.THEME;
    }

    if (!hasFetchedResultContent) {
      return Step.WAIT;
    }

    return Step.SHARE;
  },
);
