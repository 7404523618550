/**
 * IMPORTS
 */

import { Text, Box, Stack, ButtonGroup, IconButton, AspectRatio, Container, Center, Heading, Button } from '@chakra-ui/react';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FaDownload, FaFacebook, FaInstagram, FaSnapchat, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { connect, ConnectedProps } from 'react-redux';

import { useEventTracker } from '../../hooks';
import { MSState } from '../../reducers';
import { getPickedContent, getSessionId } from '../../selectors';
import Header from '../common/Header';
import { useTranslation } from '../../utils/translate';
import FullHeightFlex from '../common/FullHeightFlex';
import Footer from '../common/Footer';
import config from '../../utils/config';
import MinHSpacer from '../common/MinHSpacer';

/**
 * TYPES
 */

declare global {
  // eslint-disable-next-line
  interface HTMLVideoElement {
    webkitRequestFullscreen?: HTMLVideoElement['requestFullscreen'];
    webkitEnterFullscreen?: HTMLVideoElement['requestFullscreen'];
    mozRequestFullScreen?: HTMLVideoElement['requestFullscreen'];
    msRequestFullscreen?: HTMLVideoElement['requestFullscreen'];
  }
}

/**
 * UTILS
 */

// function getShareUrl(id: string, productId: string) {
//   return `${window.location.origin}/share/${id}/${productId}`;
// }

// function windowOpen(url: string) {
//   const config = {
//     location: 'no',
//     toolbar: 'no',
//     status: 'no',
//     directories: 'no',
//     menubar: 'no',
//     scrollbars: 'yes',
//     resizable: 'no',
//     centerscreen: 'yes',
//     chrome: 'yes',
//   };

//   return window.open(url, '', (Object.keys(config) as Array<keyof typeof config>).map((k) => `${k}=${config[k]}`).join(','));
// }

/**
 * CORE
 */

const connector = connect(
  (state: MSState) => ({
    id: getSessionId(state),
    content: getPickedContent(state),
  }),
  {

  },
);

function CTA() {
  const { t } = useTranslation();
  
  const handleClick = useCallback(() => {
    const uri = t('output.cta-link') as string;
    if (uri) {
      window.open(uri, '_self');
    }
  }, [t]);

  return (
    <Button
      size="md"
      colorScheme="brand"
      onClick={handleClick}
    >
      {t('output.cta')}
    </Button>
  );
}

type ShareScreenProps = ConnectedProps<typeof connector> & {};

function ShareScreen({
  id,
  content,
}: ShareScreenProps) {
  const [isFullscreen, setFullscreen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation();
  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent({ eventType: 'STEP_START', step: 'SHARE' })
  }, [trackEvent])

  const triggerDownload = useCallback(() => {
    if (content?.download) {
      window.open(content.download, '_self');
    }
  }, [content?.download])

  const handleDownload = useCallback(() => {
    if (id && content?.download) {
      const { productId } = content;
      trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'download' });
      triggerDownload();
    }
  }, [content, trackEvent, id, triggerDownload]);

  const handleWhatsapp = useCallback(() => {
    if (id && content?.productId) {
      const { productId } = content;
      trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'whatsapp' });
      // const text = encodeURIComponent(getShareUrl(id, productId));
      // windowOpen(`https://wa.me/?text=${text}`);
      triggerDownload();
    }
  }, [id, content, trackEvent, triggerDownload]);

  // const handleLinkedin = useCallback(() => {
  //   if (id && content?.productId) {
  //     const { productId } = content;
  //     trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'linkedin' });
  //     const url = encodeURIComponent(getShareUrl(id, productId));
  //     windowOpen(`https://linkedin.com/shareArticle?url=${url}&mini=true`);
  //   }
  // }, [id, content, trackEvent]);

  const handleFacebook = useCallback(() => {
    if (id && content?.productId) {
      const { productId } = content;
      trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'facebook' });
      // const url = encodeURIComponent(getShareUrl(id, productId));
      // windowOpen(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
      triggerDownload();
    }
  }, [id, content, trackEvent, triggerDownload]);

  const handleSnapchat = useCallback(() => {
    if (id && content?.productId) {
      const { productId } = content;
      trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'snapchat' });
      triggerDownload();
    }
  }, [id, content, trackEvent, triggerDownload]);

  const handleInstagram = useCallback(() => {
    if (id && content?.productId) {
      const { productId } = content;
      trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'instagram' });
      triggerDownload();
    }
  }, [id, content, trackEvent, triggerDownload]);

  const handleTiktok = useCallback(() => {
    if (id && content?.productId) {
      const { productId } = content;
      trackEvent({ eventType: 'SHARE', sessionId: id, productId, platform: 'tiktok' });
      triggerDownload();
    }
  }, [id, content, trackEvent, triggerDownload]);

  useEffect(() => {
    const onFullscreenChange = () => setFullscreen(val => !val);
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  const handlePlay = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    videoRef.current.muted = false;
    videoRef.current.play();

    const fn = videoRef.current.requestFullscreen
      || videoRef.current.webkitRequestFullscreen /* Safari */
      || videoRef.current.mozRequestFullScreen    /* Mozilla */
      || videoRef.current.webkitEnterFullscreen   /* Safari */
      || videoRef.current.msRequestFullscreen;    /* IE11 */
    
    if (fn) {
      fn.call(videoRef.current);
    }
  }, [videoRef]);

  if (!id || !content) {
    return <div />
  };

  return (
    <FullHeightFlex direction="column">
      <Header />
      
      <MinHSpacer />

      <Container>
        <Stack spacing="1rem">
          <Heading textAlign="center">
            {t('output.title')}
          </Heading>

          <Text textAlign="center">
            {t('output.instructions')}
          </Text>
        </Stack>
      </Container>

      <MinHSpacer />

      <Center>
        <Box
          w="255px"
          maxWidth="80%"
          position="relative"
        >
          <Box
            width="100%"
            borderRadius="2rem"
            borderWidth="3px"
            borderColor="white"
            boxShadow="0px 3px 10px gray"
            // boxShadow="2xl"
            overflow="hidden"
            transform="translateZ(0)" // fix overflow:hidden + radius on safari -> triggers GPU
          >
            <AspectRatio ratio={4/5}>
              <video
                ref={videoRef}
                src={content.url}
                playsInline={!isFullscreen}
                muted={!isFullscreen}
                style={isFullscreen ? { objectFit: 'contain' } : {}}
                loop
                autoPlay
                onClick={handlePlay}
              />
            </AspectRatio>
            {/* <Box
              position="absolute"
              w="100%"
              h="100%"
              top={0}
              left={0}
              onClick={handlePlay}
            >
              <Center h="100%">
                <Box
                  w={65}
                  h={65}
                  backgroundColor="blue.500"
                  borderRadius="50%"
                  boxShadow="0 0 10px rgba(0,0,0,0.5)"
                >
                  <Center h="100%">
                    <Text fontSize="2rem" color="white">
                      <FaPlay />
                    </Text>
                  </Center>
                </Box>
              </Center>
            </Box> */}
          </Box>
        </Box>
      </Center>

      <MinHSpacer />

      <Container>
        <Stack spacing="1rem">
          <Text textAlign="center">
            {t('output.share-instructions')}
          </Text>

          <Center>
            <ButtonGroup
              size="lg"
              isAttached
            >
              <IconButton
                aria-label="Download"
                icon={<FaDownload />}
                colorScheme="brand"
                disabled={!content.download}
                onClick={handleDownload}
              />
              <IconButton
                aria-label="Share on Facebook"
                colorScheme="facebook"
                icon={<FaFacebook />}
                onClick={handleFacebook}
              />
              <IconButton
                aria-label="Share on Whatsapp"
                colorScheme="whatsapp"
                icon={<FaWhatsapp />}
                onClick={handleWhatsapp}
              />
              {/* <IconButton
                aria-label="Share on LinkedIn"
                colorScheme="linkedin"
                icon={<FaLinkedin />}
                onClick={handleLinkedin}
              /> */}
               <IconButton
                aria-label="Share on Snapchat"
                colorScheme="snapchat"
                icon={<FaSnapchat />}
                onClick={handleSnapchat}
              />
              <IconButton
                aria-label="Share on Instagram"
                color="white"
                background="linear-gradient(45deg, #FFD600, #FF7A00, #FF0069, #D300C5, #7836FA)"
                icon={<FaInstagram />}
                onClick={handleInstagram}
                _hover={{
                  background: 'linear-gradient(45deg, #FFD600, #FF7A00, #FF0069, #D300C5, #7836FA)',
                  filter: 'brightness(0.9)',
                }}
                _active={{
                  background: 'linear-gradient(45deg, #FFD600, #FF7A00, #FF0069, #D300C5, #7836FA)',
                  filter: 'brightness(0.8)',
                }}
              />
              <IconButton
                aria-label="Share on Tiktok"
                colorScheme="tiktok"
                icon={<FaTiktok />}
                onClick={handleTiktok}
              />
            </ButtonGroup>
          </Center>

          {config.webapp?.cta && (
            <Center>
              <CTA />
            </Center>
          )}
        </Stack>
      </Container>

      <MinHSpacer />

      <Footer />
    </FullHeightFlex>
  );
}

export default connector(ShareScreen);
