/**
 * IMPORTS
 */

import { Stack, SimpleGrid } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

import { useEventTracker } from '../../hooks';
import { ThemeOption } from '../../types';
import ThemeOptionComponent from './ThemeOptionComponent';
import { getThemeImage } from '../../utils/images';

/**
 * CORE
 */

interface TCProps {
  name: string;
  options: ThemeOption[];
  onSelect: (value: string) => any;
}

function ThemeComponent({
  name,
  options,
  onSelect,
}: TCProps) {
  const [currValue, select] = useState<string>();
  const { trackEvent } = useEventTracker();

  const handleSelect = useCallback((value: string) => select(value), []);
  const handleConfirm = useCallback((value: string) => {
    trackEvent({ eventType: 'SELECT_THEME', name, value });
    onSelect(value);
  }, [onSelect, trackEvent, name]);

  return (
    <Stack>
      <SimpleGrid
        spacing="1rem"
        columns={{
          base: 1,
          md: 2,
          xl: 4,
        }}
      >
        {options.map(({ value }) => (
          <ThemeOptionComponent
            key={value}
            themeName={name}
            imageUrl={getThemeImage(name, value)}
            value={value}
            isSelected={value === currValue}
            onSelect={handleSelect}
            onConfirm={handleConfirm}
          />
        ))}
      </SimpleGrid>
    </Stack>
  );
}

export default ThemeComponent;
