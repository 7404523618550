/**
 * IMPORTS
 */

import { AnyAction, AppActionType, RequestActionType } from '../actions';
import { Content } from '../types';

/**
 * TYPES
 */

export interface ContentState {
  statusUrl?: string;
  list: Content[];
}

/**
 * CORE
 */

export const initial: ContentState = {
  list: [],
};

export default function reduce(state: ContentState = initial, action: AnyAction): ContentState {
  switch (action.type) {
    case AppActionType.RESET:
      return {
        ...initial,
      };

    case RequestActionType.NOTIFY_COMPLETION.REQUEST:
      return {
        ...state,
        statusUrl: undefined,
      };

    case RequestActionType.NOTIFY_COMPLETION.SUCCESS:
      return {
        ...state,
        statusUrl: action.statusUrl,
      };

    case RequestActionType.CONTENT_LIST.REQUEST:
    case RequestActionType.CONTENT_LIST.FAILURE:
      return {
        ...state,
        list: [],
      };

    case RequestActionType.CONTENT_LIST.SUCCESS:
      return {
        ...state,
        list: action.list,
      };

    default:
      return state;
  };
}
