/**
 * IMPORTS
 */

import config from './config';

/**
 * CORE
 */

// WEBPACK - "DYNAMIC" IMPORTS OF IMAGES using require.context

function importAll(r: __WebpackModuleApi.RequireContext) {
  const images: { [k: string]: any } = {};
  r.keys().forEach((item) => {
    // remove path + ext
    const key = item.replace('./', '').replace(/\.(png|jpe?g|svg)$/, '');
    images[key] = r(item);
  });

  const { themes } = config;
  const allThemes = themes.every(({ name, options}) => options.every(({ value }) => `themes/${name}/${value}` in images));
  if (!allThemes) {
    throw new Error('Missing theme images');
  }
  return images;
}

const images = importAll(
  require.context('../../../config/images', true, /\.(png|jpe?g|svg)$/),
);

function getImage(args: ['themes' | 'captures', ...Array<string>]) {
  const key = args.join('/');
  if (!images[key]) {
    throw new Error(`image not found: ${key}`);
  }
  return images[key].default;
}

export function getThemeImage(theme: string, value: string) {
  return getImage(['themes', theme, value]);
}

export function getCaptureImage(index: number) {
  return getImage(['captures', index.toString()]);
}
