/**
 * IMPORTS
 */

import { combineReducers } from 'redux';

import capture, { CaptureState, initial as captureInitial } from './capture';
import upload, { UploadState, initial as uploadInitial } from './upload';
import content, { ContentState, initial as contentInitial } from './content';
import theme, { ThemeState, initial as themeInitial } from './theme';
import session, { SessionState, initial as sessionInitial } from './session';

/**
 * TYPES
 */

export interface MSState {
  capture: CaptureState;
  upload: UploadState;
  content: ContentState;
  theme: ThemeState;
  session: SessionState;
}

/**
 * CORE
 */

export const initial: MSState = {
  capture: captureInitial,
  upload: uploadInitial,
  theme: themeInitial,
  content: contentInitial,
  session: sessionInitial,
};

const reducer = combineReducers({
  capture,
  upload,
  theme,
  content,
  session,
});

export default reducer;
