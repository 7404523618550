/**
 * IMPORTS
 */

import { TrackableEvent, Tracker } from './types';

/**
 * CORE
 */

export class ConsoleEventTracker implements Tracker {
  private console: Console;

  private sessionId: number;

  constructor(c: Console = console) {
    this.console = c;
    this.sessionId = Date.now();
  }

  trackEvent(event: TrackableEvent): void {
    const { eventType, ...props } = event;
    this.console.log(`[Event: ${eventType} | ${this.sessionId}]`, props);
  }

  startSession(): void {
    this.sessionId = Date.now();
    this.console.log(`[Event] New session started: ${this.sessionId}`);
  }
}
