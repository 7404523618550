/**
 * IMPORTS
 */

import { ConsoleEventTracker } from './ConsoleEventTracker';
import { TrackableEvent, Tracker } from './types';

/**
 * CORE
 */

export class Analytics implements Tracker {
  private trackers: Tracker[];

  constructor(trackers: Tracker | Tracker[] = [new ConsoleEventTracker()]) {
    if (Array.isArray(trackers)) {
      this.trackers = trackers;
    } else {
      this.trackers = [trackers];
    }
  }

  trackEvent(event: TrackableEvent): void {
    this.trackers.forEach((t) => t.trackEvent(event));
  }

  startSession(): void {
    this.trackers.forEach((t) => t.startSession());
  }
}
