/**
 * IMPORTS
 */

import amplitude, { Config } from 'amplitude-js';

import { TrackableEvent, Tracker } from './types';
import vars from '../variables';

/**
 * CORE
 */

export class AmplitudeEventTracker implements Tracker {
  private client: amplitude.AmplitudeClient;

  constructor(
    config: Config = {
      includeUtm: true,
      unsetParamsReferrerOnNewSession: true,
    },
    client?: amplitude.AmplitudeClient,
  ) {
    this.client = client || amplitude.getInstance();
    this.client.init(vars.amplitudeApiKey || '', undefined, config);
  }

  trackEvent(event: TrackableEvent): void {
    const { eventType, ...props } = event;
    this.client.logEvent(eventType, props);
  }

  startSession(): void {
    this.client.resetSessionId();
  }

  /**
   * By default, device IDs are randomly generated UUIDs, although you can define a custom Device ID
   * @param deviceId
   */
  setUserDevice(deviceId: string): void {
    this.client.setDeviceId(deviceId);
  }

  /**
   * Shorthand to set multiple user properties at once
   * @param properties
   */
  setUserProperties(properties: string): void {
    this.client.setUserProperties(properties);
  }
}
