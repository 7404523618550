/**
 * IMPORTS
 */

import { AnyAction, AppActionType, RequestActionType } from '../actions';
import { UploadInfo } from '../types';

/**
 * TYPES
 */

export interface UploadState {
  uploadInfos: Array<UploadInfo>;
  uploading: number;
  uploaded: number;
  callbackUrl?: string;
  hasNotified: boolean;
}

/**
 * CORE
 */

export const initial: UploadState = {
  uploadInfos: [],
  uploading: 0,
  uploaded: 0,
  hasNotified: false,
};

export default function reduce(state: UploadState = initial, action: AnyAction): UploadState {
  switch (action.type) {
    case AppActionType.RESET:
      return {
        ...initial,
      };

    case RequestActionType.CREATE_SESSION.SUCCESS:
      return {
        ...state,
        uploadInfos: action.uploadInfos,
        callbackUrl: action.callbackUrl,
      };

    case RequestActionType.AWS_UPLOAD.REQUEST:
      return {
        ...state,
        uploading: state.uploading + 1,
      };

    case RequestActionType.AWS_UPLOAD.SUCCESS:
      return {
        ...state,
        uploading: state.uploading - 1,
        uploaded: state.uploaded + 1,
      };

    case RequestActionType.NOTIFY_COMPLETION.SUCCESS:
      return {
        ...state,
        hasNotified: true,
      };

    default:
      return state;
  };
}
