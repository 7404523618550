/**
 * IMPORTS
 */

import { Center, Container, Image } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as actions from '../../actions';
import { MSState } from '../../reducers';
import logo from '../../assets/logo.png';

/**
 * CORE
 */


const connector = connect(
  (state: MSState) => ({
  }),
  {
    reset: actions.reset,
  },
);

type HeaderProps = ConnectedProps<typeof connector> & {};

function Header({ reset }: HeaderProps) {
  const handleClick = useCallback(
    () => reset(),
    [reset],
  );

  return (
    <Container>
      <Center>
        <Image
          src={logo}
          maxH="50px"
          marginY="1rem"
          onClick={handleClick}
        />
      </Center>
    </Container>
  )
}

export default connector(Header);
