/**
 * IMPORTS
 */

import { useCallback, useMemo } from 'react';

import { analytics, AnalyticsEvent, CommonEventProps } from '../analytics';
import vars from '../variables';

/**
* CORE
*/

function useCommonEventProps(): CommonEventProps {
  return useMemo(
    () => ({
      deploymentId: vars.deployment,
    }),
    [],
  );
}

export function useEventTracker() {
  const commonProps = useCommonEventProps();

  const trackEvent = useCallback(
    (event: AnalyticsEvent) => (
      analytics().trackEvent({
        ...commonProps,
        ...event,
      })
    ),
    [commonProps],
  );

  const startSession = useCallback(
    () => analytics().startSession(),
    [],
  );

  return useMemo(
    () => ({ trackEvent, startSession }),
    [trackEvent, startSession],
  );
}