/**
 * IMPORTS
 */

import React, { useContext } from 'react';
import { AspectRatio, Stack, Image, ButtonGroup, Button, Container } from '@chakra-ui/react';
import { FaArrowRight, FaRedo } from 'react-icons/fa';
import FallbackImage from '../common/FallbackImage';

import { useTranslation } from '../../utils/translate';
import CaptureContext from './CaptureContext';

/**
 * CORE
 */

interface PreviewStepProps {
  photoUri: string;
  onValidate: (dataUri: string) => any;
  onRetry: () => any;
}

function PreviewStep({
  photoUri,
  onValidate,
  onRetry,
}: PreviewStepProps) {
  const { t } = useTranslation();
  const { captureIndex } = useContext(CaptureContext);

  return (
    <Stack spacing="1rem">
      <AspectRatio ratio={1}>
        <Image
          src={photoUri}
          alt="Your Selfie"
          fallback={<FallbackImage />}
        />
      </AspectRatio>

      <Container>
        <ButtonGroup w="100%">
          <Button
            size="lg"
            isFullWidth
            onClick={() => onRetry()}
            leftIcon={<FaRedo />}
          >
            {t([`capture_${captureIndex}.retry-button`, 'capture.retry-button'])}
          </Button>

          <Button
            size="lg"
            colorScheme="brand"
            isFullWidth
            onClick={() => onValidate(photoUri)}
            rightIcon={<FaArrowRight />}
          >
            {t([`capture_${captureIndex}.proceed-button`, 'capture.proceed-button'])}
          </Button>
        </ButtonGroup>
      </Container>
    </Stack>
  );
}

export default PreviewStep;
