/**
 * IMPORTS
 */

import { Box, useTimeout } from '@chakra-ui/react';
import { useState } from 'react';

/**
 * CORE
 */

function Delayed(props: { delay: number, children: React.ReactNode }) {
  const [hidden, setHidden] = useState(true);
  useTimeout(
    () => setHidden(false),
    props.delay,
  );
  return (
    <Box visibility={hidden ? 'hidden' : 'visible'}>
      {props.children}
    </Box>
  );
}

export default Delayed;
