/**
 * IMPORTS
 */

import { AnyAction, AppActionType } from '../actions';
import { Theme } from '../types';
import config from '../utils/config';

/**
 * TYPES
 */

export interface ThemeState {
  themes: Theme[];
  picked: string[];
}

/**
 * CORE
 */

export const initial: ThemeState = {
  themes: config.themes,
  picked: [],
};

export default function reduce(state: ThemeState = initial, action: AnyAction): ThemeState {
  switch (action.type) {
    case AppActionType.RESET:
      return {
        ...initial,
      };

    case AppActionType.PUSH_THEME:
      return {
        ...state,
        picked: [
          ...state.picked,
          action.theme,
        ],
      };

    case AppActionType.POP_THEME:
      return {
        ...state,
        picked: state.picked.slice(0, -1),
      };

    default:
      return state;
  };
}
