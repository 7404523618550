/**
 * IMPORTS
 */

import * as reactI18Next from 'react-i18next';

/**
 * CORE
 */

// WEBPACK - "DYNAMIC" IMPORTS OF TRANSLATIONS using require.context

function importAll(r: __WebpackModuleApi.RequireContext) {
  const imported: { [k: string]: any } = {};
  r.keys().forEach((item) => {
    // keep only the 2 chars lang
    const key = item.replace('./', '').replace(/\/webapp.json$/, '');
    imported[key] = r(item);
  });

  console.log(imported);
  return imported;
}

const translations = importAll(
  require.context('../../../config/translations', true, /.{2}\/webapp.json$/),
);

export const useTranslation = () => reactI18Next.useTranslation('webapp');
export const resources = Object.keys(translations)
  .map(lang => ({ [lang]: { webapp: translations[lang] } }))
  .reduce((acc, c) => ({ ...acc, ...c }), {});
