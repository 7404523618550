/**
 * IMPORTS
 */

import { AppActionType, AnyAction } from '../actions';

import config from '../utils/config';

/**
 * TYPES
 */

export interface CaptureState {
  expected: number;
  validated: number;
  photoUri?: string;
}

/**
 * CORE
 */

export const initial: CaptureState = {
  expected: config.captures.count,
  photoUri: undefined,
  validated: 0,
};

export default function reduce(state: CaptureState = initial, action: AnyAction): CaptureState {
  switch (action.type) {
    case AppActionType.RESET:
      return {
        ...initial,
      };

    case AppActionType.USE_PHOTO:
      return {
        ...state,
        photoUri: action.dataUri,
      };

    case AppActionType.VALIDATE_PHOTO:
      return {
        ...state,
        validated: state.validated + 1,
      };

    case AppActionType.RESET_PHOTO:
      return {
        ...state,
        photoUri: undefined,
      };

    default:
      return state;
  };
}
