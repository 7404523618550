/**
 * IMPORTS
 */

import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { use100vh } from 'react-div-100vh';

/**
 * CORE
 */

function FullHeightFlex(props: FlexProps) {
  const height = use100vh();
  const style = {
    minHeight: height ? `${height}px` : '100vh',
    ...props.style,
  };
  return <Flex {...props} style={style} />;
}

export default FullHeightFlex;
