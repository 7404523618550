/**
 * IMPORTS
 */

import { Action } from 'redux';
import { v4 as uuid } from 'uuid';

import { Content, UploadInfo } from '../types';

/**
 * TYPES
 */

export enum CoreRequestActionType {
  CREATE_SESSION    = 'CREATE_SESSION',
  AWS_UPLOAD        = 'AWS_UPLOAD',
  NOTIFY_COMPLETION = 'NOTIFY_COMPLETION',
  CONTENT_LIST      = 'CONTENT_LIST',
}

type RequestType<T extends CoreRequestActionType = CoreRequestActionType> = `${T}_REQUEST`;
type SuccessType<T extends CoreRequestActionType = CoreRequestActionType> = `${T}_SUCCESS`;
type FailureType<T extends CoreRequestActionType = CoreRequestActionType> = `${T}_FAILURE`;

type StageType<T extends CoreRequestActionType> = {
  REQUEST: RequestType<T>;
  SUCCESS: SuccessType<T>;
  FAILURE: FailureType<T>;
}

function requestType<T extends CoreRequestActionType>(t: T) {
  return `${t}_REQUEST` as RequestType<T>;
}

function successType<T extends CoreRequestActionType>(t: T) {
  return `${t}_SUCCESS` as SuccessType<T>;
}

function failureType<T extends CoreRequestActionType>(t: T) {
  return `${t}_FAILURE` as FailureType<T>;
}

function coreType<T extends CoreRequestActionType>(val: RequestType<T> | SuccessType<T> | FailureType<T>) {
  return val.slice(0, -8) as CoreRequestActionType;
}

function makeStageType<T extends CoreRequestActionType>(t: T): StageType<T> {
  return {
    REQUEST: requestType(t),
    SUCCESS: successType(t),
    FAILURE: failureType(t),
  };
}

type LookupType = {
  [K in CoreRequestActionType]: StageType<K>;
}

export const RequestActionType: LookupType = Object.fromEntries(
  Object.keys(CoreRequestActionType).map((k) => [k, makeStageType(k as CoreRequestActionType)]),
) as LookupType;

export type RequestAction<T extends CoreRequestActionType = CoreRequestActionType, A extends {} = {}> = Action<RequestType<T>> & { requestId: string } & A;

type ExtractCoreType<T> = T extends RequestAction<infer U> ? U : never;

type SuccessAction<T extends RequestAction, A extends {} = {}> = Action<SuccessType<ExtractCoreType<T>>> & { requestAction: T } & A;
export type FailureAction<T extends RequestAction, A extends {} = {}> = Action<FailureType<ExtractCoreType<T>>> & { requestAction: T, code: number, message: string } & A;

export function isFailureAction<T extends RequestAction>(action: SuccessAction<T> | FailureAction<T>): action is FailureAction<T> {
  return action.type.endsWith('_FAILURE');
}

export function isResponseAction<T extends RequestAction>(action: Action): action is SuccessAction<T> | FailureAction<T> {
  return Object.values(CoreRequestActionType).some((prefix) => action.type === successType(prefix) || action.type === failureType(prefix));
}

/**
 * UTILS
 */

function createRequest<T extends CoreRequestActionType, U>(t: T, a: U): RequestAction<T, U> {
  return {
    type: requestType(t),
    requestId: uuid(),
    ...a,
  };
}

function createSuccess<T extends RequestAction, U>(requestAction: T, a: U): SuccessAction<T, U> {
  return {
    type: (successType(coreType(requestAction.type))) as `${ExtractCoreType<T>}_SUCCESS`,
    requestAction,
    ...a,
  };
}

function createFailure<T extends RequestAction>(requestAction: T, code: number, message: string): FailureAction<T> {
  return {
    type: (failureType(coreType(requestAction.type))) as `${ExtractCoreType<T>}_FAILURE`,
    requestAction,
    code,
    message,
  };
}

/**
 * CORE
 */

//
// UPLOAD INFO
//

interface CreateSessionSuccessArgs {
  id: string;
  uploadInfos: Array<UploadInfo>;
  callbackUrl: string;
}

export type CreateSessionRequestAction = RequestAction<CoreRequestActionType.CREATE_SESSION>;
export type CreateSessionSuccessAction = SuccessAction<CreateSessionRequestAction, CreateSessionSuccessArgs>;
export type CreateSessionFailureAction = FailureAction<CreateSessionRequestAction>;

export function createSession(): CreateSessionRequestAction {
  return createRequest(CoreRequestActionType.CREATE_SESSION, {});
}

export function createSessionSuccess(
  requestAction: CreateSessionRequestAction,
  id: string,
  uploadInfos: Array<UploadInfo>,
  callbackUrl: string,
): CreateSessionSuccessAction {
  return createSuccess(requestAction, { id, uploadInfos, callbackUrl });
}

export function createSessionFailure(
  requestAction: CreateSessionRequestAction,
  code: number,
  message: string,
): CreateSessionFailureAction {
  return createFailure(requestAction, code, message);
}

//
// AWS UPLOAD
//

interface UploadRequestArgs {
  content: string;
  uploadInfo: UploadInfo;
}

export type UploadRequestAction = RequestAction<CoreRequestActionType.AWS_UPLOAD, UploadRequestArgs>;
export type UploadSuccessAction = SuccessAction<UploadRequestAction>;
export type UploadFailureAction = FailureAction<UploadRequestAction>;

export function upload(
  dataUri: string,
  uploadInfo: UploadInfo,
): UploadRequestAction {
  return createRequest(CoreRequestActionType.AWS_UPLOAD, {
    content: dataUri,
    uploadInfo,
  });
}

export function uploadSuccess(
  requestAction: UploadRequestAction,
): UploadSuccessAction {
  return createSuccess(requestAction, {});
}

export function uploadFailure(
  requestAction: UploadRequestAction,
  code: number,
  message: string,
): UploadFailureAction {
  return createFailure(requestAction, code, message);
}

//
// NOTIFY COMPLETION
//

interface NotifyCompletionRequestArgs {
  callbackUrl: string;
}

interface NotifyCompletionSuccessArgs {
  imageUrl: string;
  statusUrl: string;
}

export type NotifyCompletionRequestAction = RequestAction<CoreRequestActionType.NOTIFY_COMPLETION, NotifyCompletionRequestArgs>
export type NotifyCompletionSuccessAction = SuccessAction<NotifyCompletionRequestAction, NotifyCompletionSuccessArgs>;
export type NotifyCompletionFailureAction = FailureAction<NotifyCompletionRequestAction>;

export function notifyCompletion(
  callbackUrl: string,
): NotifyCompletionRequestAction {
  return createRequest(CoreRequestActionType.NOTIFY_COMPLETION, { callbackUrl });
}

export function notifyCompletionSuccess(
  requestAction: NotifyCompletionRequestAction,
  imageUrl: string,
  statusUrl: string,
): NotifyCompletionSuccessAction {
  return createSuccess(requestAction, { imageUrl, statusUrl });
}

export function notifyCompletionFailure(
  requestAction: NotifyCompletionRequestAction,
  code: number,
  message: string,
): NotifyCompletionFailureAction {
  return createFailure(requestAction, code, message);
}

//
// CONTENT LIST
//

interface ContentListRequestArgs {
  statusUrl: string;
}

interface ContentListSuccessArgs {
  list: Content[];
}

export type ContentListRequestAction = RequestAction<CoreRequestActionType.CONTENT_LIST, ContentListRequestArgs>;
export type ContentListSuccessAction = SuccessAction<ContentListRequestAction, ContentListSuccessArgs>;
export type ContentListFailureAction = FailureAction<ContentListRequestAction>;

export function getContentList(
  statusUrl: string,
): ContentListRequestAction {
  return createRequest(CoreRequestActionType.CONTENT_LIST, { statusUrl });
}

export function getContentListSuccess(
  requestAction: ContentListRequestAction,
  list: Content[],
): ContentListSuccessAction {
  return createSuccess(requestAction, { list });
}

export function getContentListFailure(
  requestAction: ContentListRequestAction,
  code: number,
  message: string,
): ContentListFailureAction {
  return createFailure(requestAction, code, message);
}

/**
 * MORE EXPORTS
 */

export type AnyRequestAction =
  | CreateSessionRequestAction
  | UploadRequestAction
  | NotifyCompletionRequestAction
  | ContentListRequestAction
  | CreateSessionSuccessAction
  | UploadSuccessAction
  | NotifyCompletionSuccessAction
  | ContentListSuccessAction
  | CreateSessionFailureAction
  | UploadFailureAction
  | NotifyCompletionFailureAction
  | ContentListFailureAction;
