/**
 * IMPORTS
 */

import { AnyAction, AppActionType, RequestActionType } from '../actions';

/**
 * TYPES
 */

export interface SessionState {
  id?: string;
}

/**
 * CORE
 */

export const initial: SessionState = {

};

export default function reduce(state: SessionState = initial, action: AnyAction): SessionState {
  switch (action.type) {
    case AppActionType.RESET:
      return {
        ...initial,
      };

    case RequestActionType.CREATE_SESSION.SUCCESS:
      return {
        ...state,
        id: action.id,
      };

    default:
      return state;
  };
}
