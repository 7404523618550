/**
 * IMPORTS
 */

import { Spacer, SpacerProps } from '@chakra-ui/react';


/**
 * CORE
 */

function MinHSpacer(props: SpacerProps) {
  return <Spacer minH="1rem" {...props} />;
}

export default MinHSpacer;
