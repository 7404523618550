/**
 * IMPORTS
 */

import { Theme } from '../types';

/**
 * TYPES
 */

interface Config {
  captures: { count: number };
  themes: Array<Theme>;
  webapp?: {
    cta?: boolean;
  };
}

/**
 * CORE
 */

// WEBPACK - "DYNAMIC" IMPORTS OF CONFIG using require.context (needed because CRA doesn't allow import higher than src)
const r = require.context('../../../config/', false, /\.\/config.json$/);
export default r('./config.json') as Config;
