/**
 * GLOBALS
 */

 const {
  REACT_APP_DEPLOYMENT_ID,
  REACT_APP_AMPLITUDE_API_KEY,
} = process.env;

/**
 * CHECKS
 */

if (!REACT_APP_DEPLOYMENT_ID)         throw new Error('missing env. var. REACT_APP_DEPLOYMENT_ID');

/**
 * CORE
 */

const vars = {
  deployment:       REACT_APP_DEPLOYMENT_ID,
  amplitudeApiKey:  REACT_APP_AMPLITUDE_API_KEY,
}

export default vars;
