/**
 * IMPORTS
 */

import { Stack, Heading, Text, Container } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as actions from '../../actions';
import { MSState } from '../../reducers';
import { getPickedThemes, getThemes } from '../../selectors';
import ThemeComponent from './ThemeComponent';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { useTranslation } from '../../utils/translate';
import FullHeightFlex from '../common/FullHeightFlex';
import MinHSpacer from '../common/MinHSpacer';
import { useEventTracker } from '../../hooks';

/**
 * CORE
 */

const connector = connect(
  (state: MSState) => ({
    themes: getThemes(state),
    picked: getPickedThemes(state),
  }),
  {
    pushTheme: actions.pushTheme,
    popTheme: actions.popTheme,
  },
);

type ThemeScreenProps = ConnectedProps<typeof connector> & {};

function ThemeScreen({
  themes,
  picked,
  pushTheme,
  popTheme,
}: ThemeScreenProps) {
  const handleProceed = useCallback((value: string) => pushTheme(value), [pushTheme]);
  const { t } = useTranslation();
  // const handleBack = useCallback(() => popTheme(), [popTheme]);
  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent({ eventType: 'STEP_START', step: 'THEME' });
  }, [trackEvent]);

  const index = picked.length;
  if (index >= themes.length) {
    return <div/>;
  }

  const { name, options } = themes[index];
  return (
    <FullHeightFlex direction="column">
      <Header />

      <MinHSpacer />

      <Container>
        <Stack spacing="1rem">
          <Heading textAlign="center">
            {t([`theme_${name}.title`, 'theme.title'])}
          </Heading>

          <Text align="center">
            {t([`theme_${name}.instructions`, 'theme.instructions'])}
          </Text>
        </Stack>
      </Container>

      <MinHSpacer />

      <ThemeComponent
        name={name}
        options={options}
        onSelect={handleProceed}
      />

      <MinHSpacer />

      <Footer />
    </FullHeightFlex>
  )
}

export default connector(ThemeScreen);
