/**
 * IMPORTS
 */

import React, { useContext } from 'react';
import { Text } from '@chakra-ui/react';

import { useTranslation } from '../../utils/translate';
import CaptureContext from './CaptureContext';

/**
 * CORE
 */

function CaptureCount() {
  const { t } = useTranslation();
  const { captureIndex, expectedCount } = useContext(CaptureContext);
  
  if (expectedCount === 1) {
    return null;
  }

  return (
    <Text
      textAlign="center"
      textColor="gray.500"
      fontSize="xs"
    >
      {t([`capture_${captureIndex}.count`, 'capture.count'], { current: captureIndex, total: expectedCount })}
    </Text>
  )
}

export default CaptureCount;
