/**
 * TYPES
 */

export enum AppActionType {
  USE_PHOTO      = 'USE_PHOTO',
  VALIDATE_PHOTO = 'VALIDATE_PHOTO',
  RESET_PHOTO    = 'RESET_PHOTO',
  UPLOAD_PHOTO   = 'UPLOAD_PHOTO',
  PUSH_THEME     = 'PUSH_THEME',
  POP_THEME      = 'POP_THEME',
  RESET          = 'RESET',
}

export interface UsePhotoAction {
  type: AppActionType.USE_PHOTO,
  dataUri: string;
}

export interface ValidatePhotoAction {
  type: AppActionType.VALIDATE_PHOTO,
}

export interface ResetPhotoAction {
  type: AppActionType.RESET_PHOTO,
}

export interface UploadPhotoAction {
  type: AppActionType.UPLOAD_PHOTO,
  dataUri: string;
}

export interface PushThemeAction {
  type: AppActionType.PUSH_THEME;
  theme: string;
}

export interface PopThemeAction {
  type: AppActionType.POP_THEME;
}

export interface ResetAction {
  type: AppActionType.RESET;
}

export type AnyAppAction = 
  | UsePhotoAction
  | ValidatePhotoAction
  | ResetPhotoAction
  | UploadPhotoAction
  | PushThemeAction
  | PopThemeAction
  | ResetAction;

  /**
   * CORE
   */


export function usePhoto(
  dataUri: string,
): UsePhotoAction {
  return {
    type: AppActionType.USE_PHOTO,
    dataUri,
  };
}

export function validatePhoto(): ValidatePhotoAction {
  return {
    type: AppActionType.VALIDATE_PHOTO,
  };
}

export function resetPhoto(): ResetPhotoAction {
  return {
    type: AppActionType.RESET_PHOTO,
  };
}

export function uploadPhoto(
  dataUri: string,
): UploadPhotoAction {
  return {
    type: AppActionType.UPLOAD_PHOTO,
    dataUri,
  };
}

export function pushTheme(
  theme: string,
): PushThemeAction {
  return {
    type: AppActionType.PUSH_THEME,
    theme,
  };
}

export function popTheme(): PopThemeAction {
  return {
    type: AppActionType.POP_THEME,
  };
}

export function reset(): ResetAction {
  return {
    type: AppActionType.RESET,
  };
}
