/**
 * IMPORTS
 */

import React, { useEffect } from 'react';

import { useEventTracker } from '../../hooks';
import { useTranslation } from '../../utils/translate';
import SpinningWheel from '../common/SpinningWheel';

/**
 * CORE
 */

function Upload() {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent({ eventType: 'STEP_START', step: 'UPLOAD' })
  }, [trackEvent]);

  return (
    <SpinningWheel text={t('common.uploading') as string} />
  )
}

export default Upload;
