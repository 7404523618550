/**
 * IMPORTS
 */

import React from 'react';
import { CircularProgress, CircularProgressLabel, Text, Stack } from '@chakra-ui/react';

import FullHeightFlex from './FullHeightFlex';
import MinHSpacer from './MinHSpacer';
/**
 * CORE
 */

interface SpinningWheelProps {
  text: string;
  centerLabel?: string;
}

function SpinningWheel({ text, centerLabel }: SpinningWheelProps) {
  return (
    <FullHeightFlex direction="column">
      <MinHSpacer />
      <Stack align="center">
        <CircularProgress
          color="brand.500"
          isIndeterminate
        >
          {centerLabel && (
            <CircularProgressLabel>
              {centerLabel}
            </CircularProgressLabel>
          )}
        </CircularProgress>
        <Text>
          {text}
        </Text>
      </Stack>
      <MinHSpacer />
    </FullHeightFlex>
  );
}
export default SpinningWheel;
